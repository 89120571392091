<template>
  <div id="phishing" v-if="isCampaignsActive">
    <div
      class="d-flex justify-content-between subheader py-2 py-lg-4 subheader-transparent"
      id="kt_subheader"
    >
      <!--begin::Details-->
      <div class="d-flex align-items-center flex-wrap mr-2">
        <!--begin::Title-->
        <h5 class="text-dark font-weight-bold mt-2 mb-2 mr-5">
          {{
            $t("generic.crud.list", {
              module: $tc("menu.controller.phishing.title", 1)
            })
          }}
        </h5>
        <!--end::Title-->
        <!--begin::Separator-->
        <div
          class="
              subheader-separator subheader-separator-ver
              mt-2
              mb-2
              mr-5
              bg-gray-200
            "
        ></div>
        <!--end::Separator-->
        <!--begin::Search Form-->
        <div class="d-flex align-items-center" id="kt_subheader_search">
          <span class="text-dark-50 font-weight-bold" id="kt_subheader_total"
            >{{ filtered.length }}
            {{
              $t("tables.total", {
                module: $tc("tables.campaigns", filtered.length).toLowerCase()
              })
            }}</span
          >
          <div class="ml-5">
            <div
              class="input-group input-group-sm input-group-solid"
              style="max-width: 175px"
            >
              <input
                type="text"
                class="form-control"
                id="search"
                v-model="search"
                :placeholder="$t('tables.search') + ' ...'"
              />
              <div class="input-group-append">
                <span class="input-group-text">
                  <span class="svg-icon">
                    <!--begin::Svg Icon | path:/metronic/theme/html/demo9/dist/assets/media/svg/icons/General/Search.svg-->
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M14.293 16.707a1 1 0 011.414-1.414l4 4a1 1 0 01-1.414 1.414l-4-4z" fill="#000" fill-rule="nonzero" opacity=".3"/><path d="M11 16a5 5 0 100-10 5 5 0 000 10zm0 2a7 7 0 110-14 7 7 0 010 14z" fill="#000" fill-rule="nonzero"/></g></svg>
                    <!--end::Svg Icon-->
                  </span>
                  <!--<i class="flaticon2-search-1 icon-sm"></i>-->
                </span>
              </div>
            </div>
          </div>
        </div>
        <!--end::Search Form-->
        <!--begin::Campaign kind filter-->
        <b-dropdown
          id="ddown-campaignKind"
          :text="
            activeFilters.campaignTypes.length == 0
              ? $t(`assets.titles.campaignKind`)
              : $t(`assets.titles.campaignKindFiltered`, {
                  total: activeFilters.campaignTypes.length
                })
          "
          class="ml-6"
        >
          <div
            v-for="(element, index) in campaignTypes"
            :key="element.text + index"
            class="dropdown-item bg-white text-body"
          >
            <b-form-checkbox
              @change="filter(element, 'campaignTypes')"
              :checked="activeFilters.campaignTypes.includes(element.value)"
              >{{ element.text }}</b-form-checkbox
            >
          </div>
        </b-dropdown>
        <!--end::Campaign kind filter-->
      </div>
      <!--end::Details-->
      <!--begin::Toolbar-->
      <div class="d-flex align-items-center">
        <!--begin::Button-->
        <!-- <span
          v-b-tooltip.bottom.hover
          :title="$t('menu.controller.phishing.tooltip')"
        > -->
        <b-button
          variant="light-primary"
          class="ml-2 font-weight-bold"
          @click="
            $router.push({
              name: 'CreateCampaign'
            })
          "
        >
          {{ $t("campaigns.form.create") }}
          <b-icon-calendar-plus class="ml-2" />
        </b-button>
        <!-- </span> -->
      </div>
      <!--end::Toolbar-->
    </div>
    <b-card class="card-custom">
      <b-card-body>
        <b-table
          id="phishing-table"
          :items="filtered"
          :fields="fields"
          :per-page="perPage"
          :current-page="currentPage"
          primary-key="surveyId"
          :responsive="true"
          sort-by="startDate"
          sort-desc
        >
          <template #cell(show_details)="row">
            <span v-if="row.item.children" @click="row.toggleDetails">
              <i
                :class="
                  'fa fa-caret-' + (row.detailsShowing ? 'down' : 'right')
                "
              ></i>
            </span>
          </template>
          <template #cell(campaignCategory)="row">
            <span v-if="!row.item.children">
              {{ row.item.campaignCategory }}
            </span>
            <span v-else>-</span>
          </template>
          <template #cell(campaignLevel)="row">
            <span v-if="!row.item.children">
              {{ row.item.campaignLevel }}
            </span>
            <span v-else>-</span>
          </template>
          <template #row-details="row">
            <b-card>
              <b-table
                id="subphishing-table"
                :items="row.item.children"
                :fields="subfields"
                primary-key="surveyId"
                :responsive="true"
                sort-by="startDate"
                sort-desc
              >
                <template #cell(status)="data">
                  <b-badge variant="success" v-if="data.value === 'finished'">{{
                    $t(`tables.status.${data.value}`)
                  }}</b-badge>
                  <b-badge variant="info" v-if="data.value === 'progress'">{{
                    $t(`tables.status.${data.value}`)
                  }}</b-badge>
                  <b-badge
                    variant="warning"
                    v-if="data.value === 'programmed'"
                    >{{ $t(`tables.status.${data.value}`) }}</b-badge
                  >
                  <b-badge
                    variant="dark"
                    class="bg-gray-600"
                    v-if="data.value === 'canceled'"
                    >{{ $t(`tables.status.${data.value}`) }}</b-badge
                  >
                </template>
                <template #cell(actions)="row">
                  <b-button
                    pill
                    variant="light-primary"
                    v-b-tooltip.top.hover
                    :title="$t('tables.details')"
                    class="mr-4"
                    @click="
                      $router.push({
                        name: 'ViewCampaign',
                        params: { id: row.item.campaignId }
                      })
                    "
                  >
                    <b-icon-bar-chart />
                  </b-button>
                  <b-button
                    v-if="
                      getStatus(row.item) !== 'finished' &&
                        getStatus(row.item) !== 'canceled'
                    "
                    pill
                    variant="light-danger"
                    v-b-tooltip.top.hover
                    :title="$t('tables.pause')"
                    @click="deleteCampaign(row.item.campaignId)"
                  >
                    <b-icon-pause-fill />
                  </b-button>
                </template>
              </b-table>
            </b-card>
          </template>
          <template #cell(status)="data">
            <b-badge
              class="text-primary"
              variant="light"
              v-if="data.value === 'automatic'"
              >{{ $t(`tables.status.${data.value}`) }}</b-badge
            >
            <b-badge variant="success" v-if="data.value === 'finished'">{{
              $t(`tables.status.${data.value}`)
            }}</b-badge>
            <b-badge variant="info" v-if="data.value === 'progress'">{{
              $t(`tables.status.${data.value}`)
            }}</b-badge>
            <b-badge variant="warning" v-if="data.value === 'programmed'">{{
              $t(`tables.status.${data.value}`)
            }}</b-badge>
            <b-badge
              variant="dark"
              class="bg-gray-600"
              v-if="data.value === 'canceled'"
              >{{ $t(`tables.status.${data.value}`) }}</b-badge
            >
          </template>
          <template #cell(actions)="row">
            <b-button
              v-if="!row.item.children"
              pill
              variant="light-primary"
              class="mr-4"
              v-b-tooltip.top.hover
              :title="$t('tables.details')"
              @click="
                $router.push({
                  name: 'ViewCampaign',
                  params: { id: row.item.campaignId }
                })
              "
            >
              <b-icon-bar-chart />
            </b-button>
            <b-button
              v-if="
                !row.item.children &&
                  getStatus(row.item) !== 'finished' &&
                  getStatus(row.item) !== 'canceled'
              "
              pill
              variant="light-danger"
              v-b-tooltip.top.hover
              :title="$t('tables.pause')"
              @click="deleteCampaign(row.item.campaignId)"
            >
              <b-icon-pause-fill />
            </b-button>
          </template>
        </b-table>
        <div class="flex-wrap mx-auto">
          <b-pagination
            class="d-flex flex-wrap mt-3 py-2 mr-3"
            v-model="currentPage"
            :total-rows="filtered.length"
            :per-page="perPage"
            aria-controls="users-table"
            first-number
            pill
            last-number
            align="center"
            size="lg"
          ></b-pagination>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import moment from "moment-timezone";
import _ from "lodash";

import { mapGetters, mapActions } from "vuex";
import { deleteCampaign } from "@/api/controller/campaigns.api";

import {
  BIconCalendarPlus,
  BIconBarChart,
  BIconPauseFill
} from "bootstrap-vue";
import { escapeRegExp } from "@/utils/helpers";

export default {
  name: "Campaigns",
  data() {
    return {
      perPage: 10,
      grid: true,
      currentPage: 1,
      search: "",
      showFilters: false,
      searchRisk: false,
      searchGri: false,
      campaignTypes: [
        { text: this.$t("menu.toggle.campaignFilter.test"), value: "TEST" },
        { text: this.$t("menu.toggle.campaignFilter.normal"), value: "NORMAL" }
      ],
      activeFilters: {
        campaignTypes: ["NORMAL"]
      }
    };
  },
  components: {
    BIconCalendarPlus,
    BIconBarChart,
    BIconPauseFill
  },
  computed: {
    ...mapGetters("Constants", ["isConstantsActive", "getConstant"]),
    ...mapGetters("Phishing", [
      "groupedCampaigns",
      "campaigns",
      "isCampaignsActive"
    ]),
    ...mapGetters("Auth", ["hasModule"]),
    fields() {
      return [
        {
          key: "show_details",
          sortable: false,
          label: "",
          class: "align-middle text-center"
        },
        {
          key: "name",
          label: this.$tc("tables.campaigns", 1),
          sortable: true,
          class: "align-middle text-left"
        },
        {
          key: "campaignKind",
          label: this.$tc("tables.campaignKind", 1),
          sortable: true,
          class: "align-middle text-left"
        },
        {
          key: "campaignCategory",
          label: this.$t(`tables.campaignCategory`),
          sortable: true,
          filterByFormatted: true,
          sortByFormatted: false,
          class: "align-middle"
        },
        {
          key: "campaignLevel",
          label: this.$t(`tables.campaignLevel`),
          sortable: true,
          filterByFormatted: true,
          sortByFormatted: false,
          class: "align-middle"
        },
        {
          key: "startDate",
          label: this.$t(`tables.startDate`),
          sortable: true,
          filterByFormatted: true,
          sortByFormatted: false,
          class: "align-middle",
          formatter: val => {
            return val && moment(val).isValid()
              ? moment(val).format("ll")
              : "-";
          }
        },
        {
          key: "endDate",
          label: this.$t(`tables.endDate`),
          sortable: true,
          filterByFormatted: true,
          sortByFormatted: false,
          class: "align-middle",
          formatter: val => {
            return val != this.getConstant("NULL_DATE") && moment(val).isValid()
              ? moment(val).format("ll")
              : "-";
          }
        },
        {
          key: "status",
          label: this.$t("tables.status.header"),
          sortable: true,
          filterByFormatted: true,
          sortByFormatted: true,
          class: "align-middle",
          formatter: (val, key, item) => {
            return this.getStatus(item);
          }
        },
        {
          key: "actions",
          label: this.$t("tables.actions"),
          sortable: false,
          class: "align-middle"
        }
      ];
    },
    subfields() {
      return [
        {
          key: "campaignCategory",
          label: this.$t(`tables.campaignCategory`),
          sortable: true,
          filterByFormatted: true,
          sortByFormatted: false,
          class: "align-middle"
        },
        {
          key: "campaignLevel",
          label: this.$t(`tables.campaignLevel`),
          sortable: true,
          filterByFormatted: true,
          sortByFormatted: false,
          class: "align-middle"
        },
        {
          key: "startDate",
          label: this.$t(`tables.startDate`),
          sortable: true,
          filterByFormatted: true,
          sortByFormatted: false,
          class: "align-middle",
          formatter: val => {
            return val && moment(val).isValid()
              ? moment(val).format("ll")
              : "-";
          }
        },
        {
          key: "endDate",
          label: this.$t(`tables.endDate`),
          sortable: true,
          filterByFormatted: true,
          sortByFormatted: false,
          class: "align-middle",
          formatter: val => {
            return val != this.getConstant("NULL_DATE") && moment(val).isValid()
              ? moment(val).format("ll")
              : "-";
          }
        },
        {
          key: "status",
          label: this.$t("tables.status.header"),
          sortable: true,
          filterByFormatted: true,
          sortByFormatted: true,
          class: "align-middle",
          formatter: (val, key, item) => {
            return this.getStatus(item);
          }
        },
        {
          key: "actions",
          label: this.$t("tables.actions"),
          sortable: false,
          class: "align-middle"
        }
      ];
    },
    filtered() {
      let campaigns = this.groupedCampaigns;
      let result = [];
      _.each(campaigns, (campaign, key) => {
        if (key === "null") {
          result = _.concat(result, campaign);
        } else {
          const clone = _.clone(_.first(campaign));
          clone.children = [];
          _.each(campaign, child => clone.children.push(_.clone(child)));
          result.push(clone);
        }
      });
      if (this.activeFilters.campaignTypes.length > 0) {
        result = _.filter(result, campaign => {
          return this.activeFilters.campaignTypes.includes(
            campaign.campaignKind
          );
        });
      }

      if (this.search !== "") {
        const regex = new RegExp(
          `\\b${_.deburr(escapeRegExp(this.search))}`,
          "i"
        );
        result = _.filter(result, campaign => {
          return regex.test(_.deburr(campaign.name));
        });
      }
      return result;
    }
  },
  methods: {
    ...mapActions("Phishing", ["loadPhishingList"]),
    filter(id, type) {
      _.includes(this.activeFilters[type], id.value)
        ? (this.activeFilters[type] = _.reject(
            this.activeFilters[type],
            el => el === id.value
          ))
        : this.activeFilters[type].push(id.value);
    },
    getStatus(item) {
      if (item.children) {
        return "automatic";
      } else if (
        item.endDate != this.getConstant("NULL_DATE") &&
        moment(item.endDate).isBefore(moment(item.startDate))
      ) {
        return "canceled";
      } else if (moment(item.startDate).isAfter(moment())) {
        return "programmed";
      } else if (
        item.endDate == this.getConstant("NULL_DATE") ||
        moment(item.endDate).isAfter(moment())
      ) {
        return "progress";
      } else {
        return "finished";
      }
    },
    deleteCampaign(id) {
      const stakeholderId = localStorage.getItem("managedCompanyId");
      this.$swal({
        title: this.$t("tables.delete.phishing.title"),
        text: this.$t("tables.delete.phishing.description"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("tables.delete.phishing.deleteButton"),
        cancelButtonText: this.$t("tables.delete.phishing.cancelButton")
      }).then(result => {
        if (result.isConfirmed) {
          deleteCampaign(stakeholderId, id).then(() => {
            this.loadPhishingList();
            this.$swal(
              this.$t("tables.delete.phishing.deleted"),
              this.$t("tables.delete.phishing.deletedDesc"),
              "success"
            );
          });
        }
      });
    }
  },
  mounted() {
    if (this.isConstantsActive && !this.isLoading) this.loadPhishingList();
  },
  watch: {
    isConstantsActive(value) {
      if (value === true) this.loadPhishingList();
    },
    areUsersLoaded(value) {
      if (this.isConstantsActive && value === false) this.loadPhishingList();
    }
  }
};
</script>
